.timeline-item {
  p.company {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }

  p.position {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  ul {
    list-style: disc outside;
    margin-left: 2em;
    margin-top: 1em;
  }
}

.skill-cards {
  .card {
    background-color: #EEF0F7;
    border-radius: 20px;
  }
}

section.summary {
  p {
    font-style: italic;
  }
}

.container-home {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
}
